'use client';
import { FieldHookConfig, useField, useFormikContext } from 'formik';
import { Input } from '@/components/shared/material';
import { InputProps } from '@material-tailwind/react';
import { useEffect } from 'react';

type InputPropsExtended = InputProps & {
  optional?: boolean;
  localStorageKey?: string;
};

export const InputField = ({
  ...props
}: FieldHookConfig<any> & InputPropsExtended) => {
  const [field, meta] = useField(props);
  const { setFieldTouched } = useFormikContext();
  const setLocalStorage = (value: string) => {
    if (!props.localStorageKey) return;
    const storedValues = localStorage.getItem(`${props.localStorageKey}`);
    const parsedValue = storedValues ? JSON.parse(storedValues) : {};

    localStorage.setItem(
      `${props.localStorageKey}`,
      JSON.stringify({
        ...parsedValue,
        [`${props.name}`]: value,
      })
    );
  };

  useEffect(() => {
    if (props.optional) {
      const labelEl = document.querySelector(`#${props?.id} + label`);

      if (labelEl && labelEl.innerHTML.indexOf('(Optional)') === -1) {
        labelEl.innerHTML +=
          ' <span className="text-gray-400 font-light italic ml-2 inline-block">(Optional)</span>';
      }
    }
  }, [props.optional]);

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setFieldTouched(field.name, true, true);
    setLocalStorage(e.target.value);
  };
  return (
    <>
      <Input
        crossOrigin={'true'}
        {...field}
        id={props.id}
        icon={props.icon}
        maxLength={256}
        variant='standard'
        disabled={props.disabled}
        label={props.label}
        labelProps={{
          className: `${props?.labelProps?.className} ${props.optional ? '!text-gray-700' : ''}`,
          ...props.labelProps,
        }}
        onBlur={(e) => onBlur(e)}
        name={props.name}
        error={meta.touched && !!meta.error}
        required={props.required}
        placeholder={props.placeholder}
        containerProps={{ className: '!h-[56px]' }}
        type={props.type}
        className={`placeholder:opacity-0 focus:placeholder:opacity-100 ${props.className}`}
      />
      {meta.touched && meta.error ? (
        <div className='pl-2 text-xs text-red-500'>{meta.error}</div>
      ) : null}
    </>
  );
};

export default InputField;
