import { getForm } from '@/lib/hubspot/services/formService';
import React, { Suspense } from 'react';
import {
  FormBackButton,
  FormFields,
  FormSubtext,
  FormWrapper,
} from '../shared/form';
import { PortableText } from '@portabletext/react';
import { noParagraphs, paragraphs } from './PortableTextComponents';
import { PortableTextBlock } from 'sanity';
import { Form as FormType } from '@/types/types';

interface Props {
  data?: {
    sectionId?: string;
    heading?: PortableTextBlock;
    textContent?: PortableTextBlock;
    subText?: PortableTextBlock;
    formId?: string;
    formType?: string;
    displayOptions?: {
      displayType: 'default' | 'inline';
      displayMode: 'dark' | 'light';
    };
  };
}

async function FormContent({ data }: Props) {
  if (!data || !data?.formId) return null;
  const heading = data?.heading;
  const textContent = data?.textContent;
  const subText = data?.subText;
  const form = await getForm(data?.formId ?? '');
  const formType = data?.formType;
  const displayOptions = data?.displayOptions;
  return (
    <>
      {
        <>
          {/* Back button */}
          {formType !== 'thirdPartySalesLeadCapture' &&
            displayOptions?.displayType !== 'inline' && <FormBackButton />}

          {/* @ts-ignore */}
          <FormWrapper form={form} formType={formType}>
            <div
              className={`mx-auto h-full max-w-xl border border-gray-200 bg-white py-7 sm:p-8 ${displayOptions?.displayMode === 'dark' ? 'relative inline-flex flex-col bg-[radial-gradient(121.58%_121.58%_at_50%_125.11%,_#D3B203_0%,_#141414_44.39%)]' : ''} ${displayOptions?.displayType === 'inline' ? 'rounded-[10px] px-6 !pt-16 md:p-6 xl:pb-10 xl:pt-16' : 'rounded-3xl px-6 md:p-10'} `}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='32'
                height='41'
                viewBox='0 0 32 41'
                fill='none'
                className='absolute right-0 top-7'
              >
                <path
                  d='M26.0836 26.1817H13.9734V14.8745H26.0836V26.1817ZM0.513308 27.9242H13.0989V39.5533C13.0989 39.8374 13.327 40.0647 13.6122 40.0647H26.5209C26.8061 40.0647 27.0342 39.8374 27.0342 39.5533V26.9961H39.4867C39.7719 26.9961 40 26.7688 40 26.4847V13.6055C40 13.3214 39.7719 13.0942 39.4867 13.0942H26.9011V1.46499C26.9011 1.18089 26.673 0.953613 26.3878 0.953613H13.4791C13.1939 0.953613 12.9658 1.18089 12.9658 1.46499V14.0222H0.513308C0.228137 14.0222 0 14.2495 0 14.5336V27.3939C0 27.678 0.228137 27.9052 0.513308 27.9052'
                  fill='#51504D'
                />
              </svg>
              <div className={`mb-10`}>
                {heading && (
                  <h2 className='heading-sm font-display font-bold text-charcoal'>
                    <PortableText value={heading} components={noParagraphs} />
                  </h2>
                )}
                {textContent && (
                  <div className='text-charcoal'>
                    <PortableText value={textContent} components={paragraphs} />
                  </div>
                )}
              </div>
              <FormFields
                form={form as FormType}
                displayOptions={displayOptions}
              />
            </div>
          </FormWrapper>

          {subText && (
            <div className='mx-auto mt-8 max-w-md px-5'>
              <FormSubtext subText={subText} />
            </div>
          )}
        </>
      }
    </>
  );
}

export default function Form({ data }: Props) {
  const displayOptions = data?.displayOptions;
  return (
    <section
      id={data?.sectionId}
      className={`${displayOptions?.displayType === 'inline' ? 'h-full max-w-lg' : 'section-padding scroll-m-16'} ${displayOptions?.displayMode === 'dark' ? '' : 'bg-gradient-yellow bg-gradient-to-b'}`}
    >
      <div
        className={`${displayOptions?.displayType === 'inline' ? 'flex h-full [&_form]:h-full' : 'container-padding py-20'} mx-auto max-w-7xl`}
      >
        <div className=''>
          <Suspense
            fallback={
              <div
                className={`mx-auto w-full max-w-xl rounded-3xl bg-white p-8 px-6 py-7 shadow-lg sm:p-8 md:p-10`}
              >
                <div className='mb-4 h-6 animate-pulse rounded bg-gray-300'></div>
                <div className='mb-8 h-4 animate-pulse rounded bg-gray-300'></div>

                <div className='space-y-4'>
                  <div className='h-10 animate-pulse rounded bg-gray-300'></div>
                  <div className='h-10 animate-pulse rounded bg-gray-300'></div>
                  <div className='h-10 animate-pulse rounded bg-gray-300'></div>
                  <div className='h-32 animate-pulse rounded bg-gray-300'></div>
                </div>
              </div>
            }
          >
            {/* @ts-ignore */}
            <FormContent data={data} />
          </Suspense>
        </div>
      </div>
    </section>
  );
}
