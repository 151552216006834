'use client';
import React, { useEffect, useRef, useState } from 'react';
import Image from 'next/image';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import YouTube, { YouTubeProps, YouTubeEvent } from 'react-youtube';
import { m, useInView } from 'framer-motion';
import { useMatchMedia } from '@/lib/matchMedia';
import { PortableTextBlock } from 'sanity';
import { components } from '../sections';
import { PortableText } from '@portabletext/react';

export default function YoutubeVideo({
  video,
  showVideo,
  videoRef,
  className,
  image,
  videoClass,
  overlayClass,
  thumbnailClass,
  alwaysOn = false,
  iframeClass,
}: {
  video: {
    youtubeVideo: {
      id: string;
    };
    videoText: PortableTextBlock;
    previewTiming: {
      start: number;
      end: number;
    };
    youtubeThumbnail: {
      url: string;
      alt: string;
      blurDataUrl: string;
    };
  };
  videoRef: React.RefObject<Element>;
  image?: {
    url: string;
    alt: string;
    mediaAlt?: string;
    blurDataUrl: string;
  };
  showVideo: (videoId: string) => void;
  className?: string;
  videoClass?: string;
  overlayClass?: string;
  thumbnailClass?: string;
  alwaysOn?: boolean;
  iframeClass?: string;
}) {
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const [player, setPlayer] = useState<YouTubeEvent['target'] | null>(null);

  const isInViewIntial = useInView(videoRef, { amount: 0.4, once: true });
  const isInView = useInView(videoRef, { amount: 0.5 });

  const videoIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const videoStarted = useRef<boolean>(false);

  const isDesktopResolution = useMatchMedia('(min-width:1080px)', true);

  const playerStart = video.previewTiming.start ?? 0;
  const playerEnd = video.previewTiming.end ?? 0;

  const showVideoModal = () => {
    let videoId = video?.youtubeVideo?.id ?? '';
    showVideo(videoId);
  };

  useEffect(() => {
    if (
      (isMouseOver && isDesktopResolution && isInViewIntial) ||
      (!isDesktopResolution && isInView) ||
      alwaysOn
    ) {
      console.log({ player, playerStart, playerEnd, videoStarted });
      if (!videoStarted.current) {
        videoStarted.current = true;
      }

      // Check if player state is set and has properties
      if (player && player.g) {
        player.seekTo(playerStart);
        player.playVideo();
        setIsVideoPlaying(true);
      }
      videoIntervalRef.current = setInterval(
        () => {
          if (player && player.g && playerStart) player.seekTo(playerStart);
        },
        (playerEnd - playerStart) * 1000
      );
    } else {
      if (videoIntervalRef.current) {
        clearInterval(videoIntervalRef.current);
        videoIntervalRef.current = null;
      }
      // Check if player state is set and has properties
      if (player && player.g && videoStarted.current) {
        player.seekTo(playerStart);
        player.pauseVideo();
        setIsVideoPlaying(false);
      }

      if (player && player.g && !videoStarted.current) {
        player.playVideo();
        setTimeout(() => {
          player.seekTo(playerStart);
          player.pauseVideo();
          setIsVideoPlaying(false);
        }, 500);
      }
    }
  }, [
    isMouseOver,
    player,
    playerEnd,
    playerStart,
    isDesktopResolution,
    isInView,
    isInViewIntial,
    alwaysOn,
  ]);

  const onPlayerReady: YouTubeProps['onReady'] = (event) => {
    setPlayer(event.target);
    event.target.seekTo(playerStart);
    event.target.playVideo();
  };

  const opts: YouTubeProps['opts'] = {
    height: '390',
    width: '640',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      mute: 1,
      playinline: 1,
      disablekb: 1,
      start: playerStart,
      end: playerEnd,
      loop: 1,
      controls: 0,
    },
    iframeClassName: 'w-full h-full',
  };

  return (
    <>
      {video && (
        <div
          onMouseOver={() => setIsMouseOver(true)}
          onMouseLeave={() => setIsMouseOver(false)}
          onClick={showVideoModal}
          className={`full relative h-full cursor-pointer overflow-hidden lg:min-w-[420px] ${className}`}
        >
          {isInViewIntial && (
            <>
              {/* @ts-ignore  */}
              <YouTube
                videoId={video.youtubeVideo.id}
                opts={opts}
                className={`relative h-full w-full overflow-hidden rounded-3xl object-cover ${videoClass}`}
                iframeClassName={`absolute left-[-45%] top-[-45%] h-[190%] w-[190%] pointer-events-none ${iframeClass}`}
                onReady={onPlayerReady}
              />
            </>
          )}

          {video.videoText && (
            <div className='absolute left-0 top-0 z-[3] flex h-full w-full flex-col p-5'>
              <div className='relative z-10 mt-auto w-full rounded-xl border border-white/50 bg-gradient-to-b from-white/[15%] to-gray-500/[15%] font-medium backdrop-blur-[2px]'>
                <div className='flex justify-center px-3 py-2 text-xl text-white shadow-black/10 [text-shadow:_0_2px_2px_var(--tw-shadow-color)] lg:px-5 lg:py-3.5 lg:text-2xl [&_path]:fill-white'>
                  <span className='inline-block max-w-[355px] whitespace-nowrap'>
                    <PortableText
                      value={video.videoText}
                      components={components}
                    ></PortableText>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      className='ml-3 inline-block transition duration-500 group-hover:scale-110'
                      width='22'
                      height='22'
                      viewBox='0 0 22 22'
                      fill='none'
                    >
                      <path
                        d='M10.5625 0C8.47344 0 6.43129 0.61948 4.69429 1.7801C2.9573 2.94072 1.60348 4.59036 0.804027 6.52041C0.00457657 8.45045 -0.204596 10.5742 0.20296 12.6231C0.610517 14.6721 1.6165 16.5541 3.09369 18.0313C4.57088 19.5085 6.45294 20.5145 8.50186 20.922C10.5508 21.3296 12.6746 21.1204 14.6046 20.321C16.5346 19.5215 18.1843 18.1677 19.3449 16.4307C20.5055 14.6937 21.125 12.6516 21.125 10.5625C21.122 7.76206 20.0083 5.07716 18.0281 3.09695C16.0478 1.11674 13.3629 0.00295732 10.5625 0ZM10.5625 19.5C8.79483 19.5 7.06686 18.9758 5.5971 17.9938C4.12733 17.0117 2.98179 15.6158 2.30533 13.9827C1.62887 12.3496 1.45188 10.5526 1.79674 8.81888C2.14159 7.08517 2.99281 5.49266 4.24274 4.24273C5.49267 2.9928 7.08518 2.14159 8.81888 1.79673C10.5526 1.45188 12.3496 1.62887 13.9827 2.30533C15.6159 2.98178 17.0117 4.12733 17.9938 5.59709C18.9758 7.06685 19.5 8.79483 19.5 10.5625C19.4973 12.932 18.5548 15.2038 16.8793 16.8793C15.2038 18.5548 12.9321 19.4973 10.5625 19.5ZM14.2634 9.88609L9.38844 6.63609C9.26605 6.55443 9.12378 6.50754 8.97682 6.50042C8.82986 6.4933 8.68373 6.52622 8.55402 6.59567C8.4243 6.66511 8.31588 6.76847 8.24032 6.89472C8.16477 7.02097 8.12491 7.16537 8.125 7.3125V13.8125C8.12491 13.9596 8.16477 14.104 8.24032 14.2303C8.31588 14.3565 8.4243 14.4599 8.55402 14.5293C8.68373 14.5988 8.82986 14.6317 8.97682 14.6246C9.12378 14.6175 9.26605 14.5706 9.38844 14.4889L14.2634 11.2389C14.3749 11.1647 14.4663 11.0642 14.5295 10.9462C14.5927 10.8282 14.6258 10.6964 14.6258 10.5625C14.6258 10.4286 14.5927 10.2968 14.5295 10.1788C14.4663 10.0608 14.3749 9.96026 14.2634 9.88609ZM9.75 12.2941V8.83594L12.348 10.5625L9.75 12.2941Z'
                        fill='none'
                      />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
          )}
          {((video.youtubeThumbnail && video.youtubeThumbnail.url) ||
            (image && image.url)) && (
            <>
              <m.div
                animate={
                  isVideoPlaying || (isMouseOver && isDesktopResolution)
                    ? { opacity: 0 }
                    : { opacity: 1 }
                }
                className={`absolute left-0 top-0 z-[1] h-full w-full overflow-hidden rounded-3xl ${thumbnailClass}`}
              >
                <Image
                  placeholder='blur'
                  blurDataURL={
                    image
                      ? image.blurDataUrl
                      : video.youtubeThumbnail.blurDataUrl
                  }
                  className='h-full w-full object-cover'
                  width={500}
                  height={500}
                  src={image ? image.url : video.youtubeThumbnail.url}
                  alt={image ? image.alt : video.youtubeThumbnail.alt}
                />
              </m.div>
            </>
          )}

          <div
            className={`absolute left-0 top-0 z-[2] h-full w-full rounded-3xl bg-gradient-to-b from-charcoal/[0.5%] to-charcoal/30 ${overlayClass}`}
          />
        </div>
      )}
    </>
  );
}
