'use client';
import { useCallback, useEffect, useState } from 'react';
import {
  mixpanelFormSubmission,
  mixpanelSetIdentity,
} from '@/lib/mixpanel/services/client';
import { submitForm } from '@/actions';
import { Form as FormType } from '@/types/types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';

import { useAtom } from 'jotai';
import { pageTitleAtom, confirmationRedirectURLAtom } from '@/store/formStore';
import { getProduct } from '@/sanity/services/productService';
import { createCheckoutCart } from '@/actions/cartActions';
import { datadogLogs } from '@datadog/browser-logs';

type Props = {
  form: FormType;
  children: React.ReactNode;
  formTitle?: string;
  cardTitle?: string;
  formType?: string;
};

export default function Form({
  form,
  children,
  formTitle,
  cardTitle,
  formType,
}: Props) {
  const [pageURL] = useAtom(confirmationRedirectURLAtom);
  const [pageTitle] = useAtom(pageTitleAtom);
  const [currentPageTitle, setCurrentPageTitle] = useState<string | undefined>(
    undefined
  );
  const [currentPageURL, setCurrentPageURL] = useState<string | undefined>(
    undefined
  );

  const fieldValue = useCallback(
    (fieldName: string) => {
      switch (fieldName) {
        case 'page_url':
          return pageURL ? pageURL : currentPageURL;
        case 'page_title':
          return pageTitle ? pageTitle : currentPageTitle;
        default:
          return 'fdfd';
      }
    },
    [pageTitle, pageURL, currentPageTitle, currentPageURL]
  );

  const [initialValues, setInitialValues] = useState<Record<string, any>>({});
  const [validationSchema, setValidationSchema] = useState<Record<string, any>>(
    {}
  );

  useEffect(() => {
    setCurrentPageTitle(document.title);
    setCurrentPageURL(window.location.href);
    let schemaFields: Record<string, Yup.AnySchema> = {};
    const tempInitialValues: Record<string, any> = { ...initialValues }; // Clone current initialValues

    form?.fieldGroups?.forEach((group) => {
      if (group.fields && group.fields.length > 0) {
        group.fields.forEach((field) => {
          // Only set initialValues for fields that haven't been initialized yet
          if (!(field.name in tempInitialValues)) {
            tempInitialValues[field.name] = ''; // Default initialization
          }

          // Set initial values for page_url and page_title
          if (field.name === 'page_url' || field.name === 'page_title') {
            tempInitialValues[field.name] = fieldValue(field.name);
          }

          let validator: Yup.AnySchema = Yup.string(); // Default to string validation

          if (field.fieldType === 'email') {
            const emailRegex = new RegExp(
              '^(?!.*\\#\\@)([a-zA-Z0-9]+([._\\-][a-zA-Z0-9]+)*)@(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,})$'
            );
            validator = Yup.string()
              .matches(emailRegex, 'Please enter a valid email address.')
              .max(250, 'Email address is too long.');
          }

          if (field.fieldType === 'message') {
            validator = Yup.string().max(500, 'Too many characters.');
          }

          if (field.fieldType === 'phone') {
            const phoneRegex = new RegExp(
              '^(?:\\+?\\d{1,4}|0)\\d{6,12}$',
              'gm'
            );
            validator = Yup.string().matches(
              phoneRegex,
              'Please enter a valid phone number.'
            );
          }

          if (field.required) {
            validator = validator
              .required('This field cannot be empty.')
              .trim();
          }

          // Your field validation logic here...

          schemaFields[field.name] = validator;
        });
      }
    });

    const yupValidationSchema = Yup.object().shape(schemaFields);
    setValidationSchema(yupValidationSchema); // Update state with all validation schema
    setInitialValues(tempInitialValues); // Update state with all initial values
    // Assuming you have a state for schemaFields or you handle it similarly
  }, [
    form,
    fieldValue,
    setCurrentPageTitle,
    setCurrentPageURL,
    setValidationSchema,
    setInitialValues,
  ]); // Re-run when form changes

  const handleSubmit = useCallback(
    async (values: any, { setSubmitting, resetForm }: any) => {
      setSubmitting(true);
      try {
        const pageURL = window.location.origin + window.location.pathname;
        const pageTitle = document.title;

        if (formType === 'thirdPartySalesLeadCapture') {
          // create shopify checkout for 3rd party sales lead capture and attach to form submission
          const selectedProductSlug = values.products;
          const selectedProduct = await getProduct(selectedProductSlug);

          if (!selectedProduct) {
            console.error('Product not found');
            return;
          }

          const shopifyCheckout = await createCheckoutCart({
            buyerIdentity: {
              email: values.email,
            },
            lines: [
              {
                merchandiseId: selectedProduct.variantGid,
                quantity: 1,
              },
            ],
          });

          if (!shopifyCheckout) {
            throw new Error('Failed to create checkout cart');
          }
          // shopify_draft_order_id
          values.shopify_draft_order_id = shopifyCheckout.id;

          // will pull from url slug in future
          values.sub_channel = 'TymeRC';
          //full checkout url
          values.checkout_url = `https://${process.env.NEXT_PUBLIC_SITE_URL}/purchase-journey?cartId=${shopifyCheckout.id}&affiliate=${values.sub_channel}`;
        }

        const response = await submitForm(form, values, pageURL, pageTitle);

        // If redirectUri is returned, redirect to that page
        if ('redirectUri' in response) {
          toast.success('Thank you. Redirecting you.', {
            position: 'bottom-center',
            icon: (
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth='1.5'
                stroke='currentColor'
                className='h-4 w-4 animate-spin'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99'
                />
              </svg>
            ),
            duration: 4000,
          });

          // Redirect user after 500 millsenconds
          setTimeout(() => {
            window.location.href = response.redirectUri;
          }, 500);
          resetForm();
        }

        if ('inlineMessage' in response) {
          toast.success(response.inlineMessage, {
            position: 'bottom-center',
            duration: 4000,
          });
          resetForm();
        }

        if (response.status === 'error') {
          console.log('error', response);
          toast.error(
            'There was a problem when trying to submit the form. Please try again, or alternatively contact us directly.',
            {
              position: 'bottom-center',
              duration: 6000,
            }
          );
          setSubmitting(false);
        }

        // Sent Mixpanel identify via email
        const { email } = values;
        mixpanelSetIdentity(email);

        // Send Mixpanel form complete event
        mixpanelFormSubmission(
          form.displayOptions.submitButtonText ?? 'submit',
          formTitle ?? form.name,
          cardTitle ?? 'No card title'
        );
      } catch (error: any) {
        // try to parse error.message if it contains a JSON string
        try {
          const errorJSON = JSON.parse(error.message);
          if (Array.isArray(errorJSON)) {
            const errorMessage = errorJSON
              .map((error) => error.message)
              .join(', ');

            toast.error(errorMessage, {
              position: 'bottom-center',
              duration: 6000,
            });
            datadogLogs.logger.error('Form submission error', {
              message: error.message,
              stack: error.stack,
            });
          }
        } catch (e) {
          // if error.message is not a JSON string, display it as is
          toast.error(
            'There was a problem when trying to submit the form. Please try again, or alternatively contact us directly.',
            {
              position: 'bottom-center',
              duration: 6000,
            }
          );
        }
      } finally {
      }
    },
    [form, formTitle, cardTitle]
  );

  return (
    <>
      {/* @ts-ignore */}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => <form onSubmit={formik.handleSubmit}>{children}</form>}
      </Formik>
    </>
  );
}
