export const fadeUp = {
  hidden: {
    opacity: 0,
    y: 30,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
};

export const fadeUpLeft = {
  hidden: {
    opacity: 0,
    y: 50,
    x: 10,
  },
  visible: {
    opacity: 1,
    y: 0,
    x: 0,
  },
};

export const fadeUpRight = {
  hidden: {
    opacity: 0,
    y: 50,
    x: -20,
  },
  visible: {
    opacity: 1,
    y: 0,
    x: 0,
  },
};

export const moveUp = {
  outOfView: {
    y: 40,
    x: 0,
  },
  visible: {
    y: 0,
    x: 0,
  },
};

export const fadeRight = {
  hidden: {
    opacity: 0,
    x: -20,
  },
  visible: {
    opacity: 1,
    x: 0,
  },
};

export const block = {
  hidden: { opacity: 0, y: 100 },
  show: { opacity: 1, y: 0 },
};

export const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
      type: 'spring',
    },
  },
};
