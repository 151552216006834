'use client';

import { MediaNewsLetterProps } from '@/types/types';
import YoutubeVideo from '../shared/YoutubeVideo';
import { useEffect, useRef, useState } from 'react';
import { PortableText } from 'next-sanity';
import { IKButton } from '../shared/material';
import { components } from './PortableTextComponents';
import { PlayCircle } from '@phosphor-icons/react';
import YouTube, { YouTubeProps } from 'react-youtube';
import { m, AnimatePresence } from 'framer-motion';
export default function MediaPlayer(props: {
  data?: MediaNewsLetterProps['mediaPlayer'];
}) {
  const videoRef = useRef(null);

  const video = props.data?.video;
  const image = props?.data?.thumbnail;

  const [activeVideo, setActiveVideo] = useState<string | null>(null);

  const opts: YouTubeProps['opts'] = {
    height: '390',
    width: '640',
    iframeClassName: 'w-full h-full',
  };

  const setActiveVideoHandler = () => {
    if (video?.youtubeVideo?.id) {
      setActiveVideo(video?.youtubeVideo?.id);
    }
  };

  useEffect(() => {
    if (activeVideo) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [activeVideo]);

  const onPlayerReady: YouTubeProps['onReady'] = (event) => {
    event.target.playVideo();
  };

  useEffect(() => {
    const handleEscKeyPress = (event: KeyboardEvent) => {
      if (
        (event.key === 'Escape' && activeVideo) ||
        (event.key === 'Esc' && activeVideo)
      ) {
        setActiveVideo(null);
      }
    };

    window.addEventListener('keydown', handleEscKeyPress);

    return () => {
      window.removeEventListener('keydown', handleEscKeyPress);
    };
  }, [activeVideo]);

  return (
    <section className='relative scroll-m-5 bg-white'>
      <div ref={videoRef} className='relative mx-auto h-full'>
        {video && (
          <YoutubeVideo
            videoRef={videoRef}
            className={`relative z-10 -mx-10 aspect-[16/13] w-[130%] sm:aspect-[16/9] md:mx-auto md:w-full`}
            videoClass='!rounded-none md:!rounded-[10px] '
            thumbnailClass='rounded-none md:!rounded-[10px]'
            overlayClass='!rounded-none md:!rounded-[10px] from-charcoal/50 bg-gradient-to-t to-charcoal/50'
            showVideo={() => {}}
            video={video}
            image={image}
            alwaysOn={true}
          />
        )}
        {/* overlay the heading subheading and button */}
        <div className='absolute bottom-12 left-0 z-20 flex w-full flex-col items-start items-stretch justify-between gap-5 sm:px-5 md:flex-row xl:px-11'>
          <div className='flex flex-col gap-3 sm:gap-4'>
            {props.data?.tag && (
              <span className='text-base font-bold uppercase text-[#EFE9DB]'>
                {props.data?.tag}
              </span>
            )}
            {props.data?.heading && (
              <div className='heading-base'>
                <PortableText
                  value={props.data?.heading}
                  components={components}
                />
              </div>
            )}
            {props.data?.subheading && (
              <div className='text-base'>
                <PortableText
                  value={props.data?.subheading}
                  components={components}
                />
              </div>
            )}
          </div>
          <div className='flex flex-grow flex-col items-start justify-end sm:items-end'>
            <div>
              {props.data?.button?.link.url && (
                <IKButton
                  variant={'filled'}
                  size={'md'}
                  onClick={setActiveVideoHandler}
                  className={`group flex max-h-[50px] w-[193px] min-w-full items-center justify-center gap-1 whitespace-nowrap bg-[#EFE9DB] px-1 text-center text-charcoal hover:!text-black md:w-full md:px-4 lg:w-[193px]`}
                >
                  <PlayCircle
                    weight='fill'
                    className='h-6 w-6 group-hover:scale-[1.05]'
                  />
                  <span className='text-base'>{props.data?.button.text}</span>
                </IKButton>
              )}
            </div>
          </div>
        </div>
      </div>

      <AnimatePresence>
        {activeVideo && (
          <m.div
            initial={false}
            className={`fixed left-0 top-0 z-[99999] h-screen w-screen bg-black/90`}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <div className='container-padding container relative flex h-full items-center justify-center'>
              <button
                className='absolute right-5 top-10 z-[100] rounded-full bg-white/10 px-4 py-4 text-white/90 transition duration-200 hover:bg-white/20 md:right-0'
                onClick={() => setActiveVideo(null)}
              >
                <svg
                  className='h-4 w-4 text-white'
                  xmlns='http://www.w3.org/2000/svg'
                  width='23'
                  height='23'
                  viewBox='0 0 23 23'
                  fill='none'
                >
                  <path
                    d='M21.5362 1.30971C21.8319 1.60542 21.9981 2.00649 21.9981 2.42469C21.9981 2.84289 21.8319 3.24396 21.5362 3.53967L3.69651 21.3794C3.4008 21.6751 2.99973 21.8412 2.58153 21.8412C2.16333 21.8412 1.76226 21.6751 1.46654 21.3794C1.17083 21.0837 1.0047 20.6826 1.0047 20.2644C1.0047 19.8462 1.17083 19.4451 1.46654 19.1494L19.3063 1.30971C19.602 1.014 20.003 0.847866 20.4212 0.847866C20.8394 0.847866 21.2405 1.014 21.5362 1.30971Z'
                    fill='currentColor'
                  />
                  <path
                    d='M21.5367 21.3807C21.241 21.6764 20.8399 21.8425 20.4217 21.8425C20.0035 21.8425 19.6025 21.6764 19.3068 21.3807L1.46705 3.54099C1.17134 3.24528 1.00521 2.84421 1.00521 2.42601C1.00521 2.00781 1.17134 1.60674 1.46705 1.31103C1.76276 1.01532 2.16383 0.849186 2.58203 0.849186C3.00023 0.849186 3.4013 1.01532 3.69701 1.31103L21.5367 19.1507C21.8324 19.4465 21.9986 19.8475 21.9986 20.2657C21.9986 20.6839 21.8324 21.085 21.5367 21.3807Z'
                    fill='currentColor'
                  />
                </svg>
              </button>
              {/* @ts-ignore  */}
              <YouTube
                videoId={activeVideo}
                opts={opts}
                className='aspect-video w-full max-w-4xl overflow-hidden rounded-md bg-black [aspect-ratio:_16_/_9]'
                iframeClassName='aspect-video h-full w-full [aspect-ratio:16_/_9]'
                onReady={onPlayerReady}
              />
            </div>
          </m.div>
        )}
      </AnimatePresence>
    </section>
  );
}
